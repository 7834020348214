<template>
  <div class="row">
    <div class="col-sm-12">
      <form @submit.prevent="handleSubmit">
        <div class="row">
          <div class="form-group col-sm-12 col-md-12 required">
            <label for="account_id">Account:</label>
            <Select2 name="account_id" required v-model="account_id" :options="accountOptions" @change="onChangeAccount($event)" @select="onChangeAccount($event)"/>
          </div>
          <div class="form-group col-sm-12 col-md-12 required">
            <label for="vehicle_id">Vehicle:</label>
            <Select2 name="vehicle_id" required v-model.number="vehicle_id" :options="vehicleOptions" @change="onChangeVehicle($event)" @select="onChangeVehicle($event)"/>
          </div>
          <div class="form-group col-sm-12 col-md-6 required">
            <label for="from_date">From Date:</label>
            <input class="form-control" required type="date" v-model="from_date">
          </div>
          <div class="form-group col-sm-12 col-md-6 required">
            <label for="to_date">To Date:</label>
            <input class="form-control" required type="date" v-model="to_date">
          </div>
          <div class="form-group col-sm-12">
            <input class="btn btn-primary" type="submit" value="Show" :disabled="this.onRequest">
          </div>
        </div>
      </form>
    </div>
    <div v-if="total_part_usage" class="col-sm-12">
      <div class="row">
        <div class="form-group col-sm-12 col-md-3">
          <label for="total_non_part">Total Non Part Cost:</label>
          <input type="text" class="form-control text-right" name="total_non_part" v-model="total_non_part" disabled>
        </div>
        <div class="form-group col-sm-12 col-md-3">
          <label for="total_part_usage">Total Part Usage:</label>
          <input type="text" class="form-control text-right" name="total_part_usage" v-model="total_part_usage" disabled>
        </div>
        <div class="form-group col-sm-12 col-md-3">
          <label for="total_cost">Total Cost:</label>
          <input type="text" class="form-control text-right" name="total_cost" v-model="total_cost" disabled>
        </div>
        <div class="form-group col-sm-12 col-md-3">
          <label for="total_estimation">Total Estimation Cost:</label>
          <input type="text" class="form-control text-right" name="total_estimation" v-model="total_estimation" disabled>
        </div>
      </div>
    </div>
    <div class="col-sm-12">
      <Datatable v-bind:entries="data" :columns="columns" :title="'Work Order'"/>
    </div>
    <div v-if="this.onRequest" class="modal-backdrop fade show" style="display:flex; align-items: center; justify-content: center;">
      <img :src="require('../assets/loading.gif')"/>
    </div>
  </div>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import Datatable from '../components/Datatable.vue'
  import { getCapability } from '../_helpers'
  import moment from 'moment'
  import Select2 from 'vue3-select2-component';
  export default {
    computed: {
      ...mapState('work_orders', ['work_orders', 'onRequest']),
      ...mapState('vehicles', ['vehicles']),
      ...mapState('accounts', ['accounts']),
      ...mapState('users', ['user']),
      columns(){
        return [
          {name: 'start', text: 'Start', order: 'desc', raw:{type:'Moment_UTC'}},
          {name: 'work_order_code', text: 'Work Order Code'},
          {name: 'vehicle', text: 'Vehicle',
            raw: {
              type: 'Sub',
              value: 'vehicle_no'
            }
          },
          {name: 'status', text: 'Status',
            raw: {
              type: 'Text',
              fields: [
                {value: '1', text: 'Pending'},
                {value: '2', text: 'Started'},
                {value: '3', text: 'On Progress'},
                {value: '4', text: 'Completed'},
              ]
            }
          },
          {name: 'service_by', text: 'Service By',
            raw: {
              type: 'Text',
              fields: [
                {value: '1', text: '3rd Party / Vendor'},
                {value: '2', text: 'Own Workshop'},
              ]
            }
          },
          {name: 'advisor', text: 'Advisor'},
          {name: 'mechanic', text: 'Mechanic'},
          {name: 'type', text: 'Type',
            raw: {
              type: 'Text',
              fields: [
                {value: '1', text: 'Service'},
                {value: '2', text: 'Inspection'},
                {value: '3', text: 'Repair'},
                {value: '4', text: 'Others'},
              ]
            }
          },                  
          {name: 'cost', text: 'Non Part Cost', class: 'text-right',
            raw: {
              type: 'Sub_format_number',
              value: 'total_cost'
            }
          },     
          {name: 'cost', text: 'Non Part Cost Status',
            raw: {
              type: 'Sub_text',
              value: 'status',
              fields: [
                {value: '0', text: 'Need Approval'},
                {value: '1', text: 'Approved'},
                {value: '2', text: 'Paid'},
              ]
            }
          },                    
          {name: 'inventory_cost', text: 'Part Usage', class: 'text-right',
            raw: {
              type: 'format_number'
            }
          },     
          {name: 'total_cost', text: 'Total Cost', class: 'text-right',
            raw: {
              type: 'format_number'
            }
          },    
          {name: 'maintenance_schedule', text: 'Maintenance Schedule Name',
            raw: {
              type: 'Sub',
              value: 'maintenance_name'
            }
          },    
          {name: 'maintenance_schedule', text: 'Estimation Cost', class: 'text-right',
            raw: {
              type: 'Sub_format_number',
              value: 'cost_estimation'
            }
          },       
          {name: 'action', text: 'Action',
            raw: {
              type: 'Action',
              fields: [
                getCapability('edit_work_order', this.user) ? {event: 'edit_work_order', text: 'View', method: 'get', class: 'btn btn-primary'} : {},
              ]
            }
          },
        ]
      }
    },
    data(){
      return {
        from_date: moment().format("YYYY-01-01"),
        to_date: moment().format("YYYY-12-31"),
        account_id: null,
        vehicle_id: null,
        accountOptions: [],
        vehicleOptions: [],
        data: [],
        total_non_part: null,
        total_part_usage: null,
        total_cost: null,
        total_estimation: null,
      }
    },
    components:{
      Datatable,
      Select2,
    },
    methods: {
      formatNumber(value){
        return value ? value.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",") : value
      },
      ...mapActions('work_orders', {getData: 'get_maintenance_history_by_vehicle'}),
      ...mapActions('vehicles', {getVehicles: 'get_vehicle_by_account'}),
      ...mapActions('accounts', ['get_all']),
      ...mapActions('alert', ['error', 'clear']),
      onChangeAccount(event){
        this.getVehicles({account_id: parseInt(event.id)})
        this.vehicle_id = null        
        this.total_non_part = null
        this.total_part_usage = null
        this.total_cost = null
        this.total_estimation = null
      },
      onChangeVehicle(){
        this.total_non_part = null
        this.total_part_usage = null
        this.total_cost = null
        this.total_estimation = null
      },
      handleSubmit(){
        if(this.onRequest) return;
        this.clear()
        const {from_date, to_date, vehicle_id} = this
        let day = moment(from_date).diff(moment(to_date), 'days')
        if(day > 0){
          this.error('Please Input Correct Dates.')
          return;
        }
        this.getData({from_date, to_date, vehicle_id})
      }
    },
    created() {
      this.$emit('onChildInit', 'History Maintenance Vehicle')
      this.get_all()
    },
    watch: {      
      vehicles(nextState, prevState){
        if(nextState !== prevState){
          this.vehicleOptions = []
          if(nextState.length > 0){
            nextState.map(item => {
              this.vehicleOptions.push({id:item.id, text:item.vehicle_no +' - '+item.vehicle_code})
            })
          }
          return
        }
      },
      accounts(nextState, prevState){
        if(nextState !== prevState){
          this.accountOptions = []
          if(nextState.length > 0){
            nextState.map(item => {
              this.accountOptions.push({id:item.id, text:item.account_name})
            })
          }
          return
        }
      },
      work_orders(nextState, prevState){
        if(nextState !== prevState){
          this.data = []
          if(nextState.length > 0){            
          this.total_non_part = 0
          this.total_part_usage = 0
          this.total_cost = 0
          this.total_estimation = 0
            nextState.map(item => {
              this.data.push({...item, ...{total_cost: item.inventory_cost + (item.cost ? item.cost.total_cost : 0)}})
              this.total_non_part += (item.cost ? item.cost.total_cost : 0)
              this.total_part_usage += item.inventory_cost
              this.total_cost += item.inventory_cost + (item.cost ? item.cost.total_cost : 0)
              this.total_estimation += item.maintenance_schedule ? item.maintenance_schedule.cost_estimation : 0
            })            
            this.total_non_part = this.formatNumber(this.total_non_part)
            this.total_part_usage = this.formatNumber(this.total_part_usage)
            this.total_cost = this.formatNumber(this.total_cost)
            this.total_estimation = this.formatNumber(this.total_estimation)
          }
          return
        }
      },
    }
  }
</script>
