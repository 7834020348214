<template>
  <form @submit.prevent="handleSubmit">
    <div class="row">
      <div class="col-sm-12">
        <div class="row">
          <div class="form-group col-sm-12 col-md-12 required">
            <label for="parent_id">Account:</label>
            <Select2 name="account_id" v-model.number="account_id" :options="accountOptions"/>
          </div>
          <div class="form-group col-sm-12">
            <input class="btn btn-primary" type="submit" value="Show" :disabled="this.onRequest">
          </div>
          <div class="form-group col-sm-12">
            <div class="custom-control custom-checkbox">
              <input type="checkbox" class="custom-control-input" id="show_traffic_checkbox"
                v-model="show_traffic">
              <label class="custom-control-label" for="show_traffic_checkbox">Show Traffic</label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">      
      <div v-if="vehicleOptions.length > 0" class="form-group col-sm-12 col-md-12">
        <label for="parent_id">Vehicle:</label>
        <Multiselect mode="multiple" v-model="vehicle_ids" :options="vehicleOptions" valueProp="valueProp" label="label" :searchable="true">
          <template v-slot:multiplelabel="{ values }">
            <div class="multiselect-multiple-label">
              <span v-for="(item2, index2) in values" :key="index2">{{item2.label}},&nbsp;&nbsp;</span>
            </div>
          </template>
        </Multiselect>
      </div>
      <div class="col-sm-12 col-md-12">
        <GMapMap
            :center="center"
            :zoom="14"
            :disableDefaultUI="true"
            :options="{
                  zoomControl: false,
                  mapTypeControl: false,
                  scaleControl: true,
                  streetViewControl: false,
                  rotateControl: true,
                  fullscreenControl: true,
            }"
            ref="gmap"
            map-type-id="terrain"
            style="width: 100%; height: 50vh"
        >
          <MapVehicleComponent
            v-for="vehicle in this.filteredData"
            :key="vehicle"
            :gmap="this.$refs.gmap"
            :selectVehicle="selectVehicle"
            :selected_vehicle="selected_vehicle"
            :gps_position="vehicle.gps_position"
            :vehicle="vehicle"/>
          <GMapPolyline v-if="selected_vehicle && selected_vehicle.gps_position && selected_vehicle.gps_position.tail"
            :path="selected_vehicle.gps_position.tail"
            :options="{ strokeColor:'#0000FF', strokeWeight: 2}"
            />
        </GMapMap>
      </div>
    </div>
    <div v-if="this.onRequest" class="modal-backdrop fade show" style="display:flex; align-items: center; justify-content: center;">
      <img :src="require('../assets/loading.gif')"/>
    </div>
  </form>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import VueGoogleMaps from '@fawmi/vue-google-maps'
  import MapVehicleComponent from '../components/MapVehicleComponent.vue'
  import Select2 from 'vue3-select2-component';
  import Multiselect from '@vueform/multiselect'
  export default {
    computed: {
        ...mapState('accounts', ['accounts']),
        ...mapState('live_maps', ['live_map', 'onRequest']),
        ...mapState('location_addresses', ['location_address']),
        google: VueGoogleMaps,        
    },
    data(){
      return {
        account_id: '',
        timer: null,
        isFitOnce: false,
        temp_selected_vehicle: null,
        selected_vehicle: null,
        center: {lat: -6.21462, lng: 106.84513},
        data: [],
        accountOptions: [],
        show_traffic: false,
        trafficLayer: null,
        vehicleOptions: [],
        filteredData: [],
        vehicle_ids: [],
        address: '-'
      }
    },
    methods: {
      handleSubmit(){
        this.selected_vehicle = null
        this.isFitOnce = false
        this.vehicle_ids = []
        this.vehicleOptions = []
        this.filteredData = []
        this.data = []
        this.reloadData()
      },
      selectVehicle(item){
        let tail = [...item.gps_position.tail]
        tail = tail.map(item => {
          return {lat: parseFloat(item.lat), lng: parseFloat(item.lng)}
        })
        item.gps_position.tail = tail
        this.temp_selected_vehicle = item
        this.getLocation({lat: item.gps_position.lat, lon: item.gps_position.lng})
      },
      ...mapActions('alert', ['error', 'clear']),
      ...mapActions('accounts', ['get_all']),
      ...mapActions('live_maps', ['get_data', 'clear_data']),
      ...mapActions('location_addresses', {getLocation: 'get_data'}),
      reloadData(){
        this.clear()
        clearInterval(this.timer)
        if(this.account_id !== ''){
          this.address = '-'
          this.temp_selected_vehicle = null
          this.get_data({account_id: this.account_id})
          const self = this;
          this.timer = setInterval(function(){
            self.reloadData()
          }, process.env.VUE_APP_RELOAD_TIME)
        }
      },
      getColor(gps_position){
        if(gps_position.speed > 0){
          return '#3bb143'
        }
        if(gps_position && gps_position.sensors && gps_position.sensors.length > 0){
          let acc = gps_position.sensors.filter(item => item.type === 'acc')
          if(acc.length > 0){
            if(acc[0].val === true){
              return '#007bff'
            }
          }
        }
        return '#ef5350'
      }
    },
    created() {
      this.$emit('onChildInit', 'Live Map')
      this.clear_data()
      this.get_all()
    },
    unmounted(){
      if(this.timer){
        clearInterval(this.timer)
      }
    },
    components:{
      MapVehicleComponent,
      Select2,
      Multiselect,
    },
    watch: {
      accounts(nextState, prevState){
        if(nextState !== prevState){
          this.accountOptions = []
          if(nextState.length > 0){
            nextState.map(item => {
              this.accountOptions.push({id:item.id, text:item.account_name})
            })
          }
          return
        }
      },
      live_map (nextState, prevState) {
        if(nextState !== prevState){
          if(nextState.length > 0){
            this.data = nextState
            if(this.filteredData.length < 1){
              if(this.vehicle_ids.length > 0){
                const that = this
                this.filteredData = this.data.filter(function(item){
                  return that.vehicle_ids.indexOf(item.id) >= 0
                })
              } else {
                this.filteredData = nextState                         
              }
            } else {              
              this.data.map(item => {
                for(let i = 0;i<this.filteredData.length;i++){
                  if(item.id == this.filteredData[i].id){
                    this.filteredData[i].gps_position = item.gps_position
                    break;
                  }
                }
              })
            }
            if(!this.isFitOnce){
              this.$refs.gmap.$mapPromise.then(() => {
                let bounds = new window.google.maps.LatLngBounds()
                nextState.forEach(loc => {
                  bounds.extend(loc.gps_position)
                })
                this.$nextTick().then(() => {
                  this.$refs.gmap.fitBounds(bounds)
                })
                this.$refs.gmap.panToBounds(bounds, 100)
              })
              this.isFitOnce = true              
              nextState.map(item => {
                this.vehicleOptions.push({valueProp: item.id, label: item.vehicle_no})
              })
            }
            if(this.selected_vehicle !== null){
              nextState.forEach(loc => {
                if(loc.id === this.selected_vehicle.id){
                  let tail = [...loc.gps_position.tail]
                  tail = tail.map(item => {
                    return {lat: parseFloat(item.lat), lng: parseFloat(item.lng)}
                  })
                  loc.gps_position.tail = tail
                  loc.gps_position.address = this.address
                  this.selected_vehicle = loc           
                }
              })
              this.$nextTick().then(() => {
                let bounds = new window.google.maps.LatLngBounds()
                bounds.extend(this.selected_vehicle.gps_position)
                this.$refs.gmap.panToBounds(bounds, 100)
              })
            }
          } 
        } 
      },
      vehicle_ids(nextState, prevState){
        if(nextState !== prevState){
          if(nextState.length > 0){
            this.filteredData = [...this.data.filter(function(item){
              return nextState.indexOf(item.id) >= 0
            })]
          } else {
            this.filteredData = this.data
          }
        }
      },
      show_traffic(nextState, prevState){
        if(nextState !== prevState){
          if(nextState == true){
            this.$refs.gmap.$mapPromise.then(map => {
              if(this.trafficLayer == null){
                this.trafficLayer = new window.google.maps.TrafficLayer()
              }
              this.trafficLayer.setMap(map)
            })
          } else {            
            this.$refs.gmap.$mapPromise.then(() => {
              if(this.trafficLayer != null)
                this.trafficLayer.setMap(null)
            })
          }
        }
      },
      location_address(nextState, prevState){
        if(nextState !== prevState && nextState.address && this.temp_selected_vehicle){
          this.temp_selected_vehicle.gps_position.address = nextState.address
          this.selected_vehicle = this.temp_selected_vehicle
          this.address = nextState.address
        }
      }
    },
  }
</script>
<style>
.item{
  width: 16vw;
  padding: 10px;
  border: 1px solid black;
  margin: 1px 1px;
}
</style>
<style src="@vueform/multiselect/themes/default.css"></style>
